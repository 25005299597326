document.addEventListener('DOMContentLoaded', function() {
    
    AOS.init({
        offset: 200,
        delay: 100,
        duration: 400,
        easing: 'ease-out',
        once: true
    });

    var rellax = new Rellax('.rellax');

    // Obfuscate phone number in WhatsApp link
    document.querySelector('.wa-link').href= 'https:' + '//' + 'wa.me' + '/' + '628' + '114' + '16' +'8' +'45' + '8'; //628114168458
});